import analytic from "./ksk/analytic";
import board from "./ksk/board";
import employee from "./ksk/employee";
import entry from "./ksk/entry";
import gates from "./ksk/gates";
import houses from "./ksk/houses";
import notifications from "./ksk/notifications";
import tenants from "./ksk/tenants";
import requests from "./ksk/requests";
import vote from "./ksk/vote";
import qr from "./ksk/qr";
import qrNext from "./ksk/qr-next";
import pricelists from "./ksk/pricelists";
import questions from "./ksk/questions";
import users from "./ksk/users";

export default function(instance) {
	return {
		// * Получение статистики по заявкам
		load_statistics(params) {
			return instance({
				url: `/requests/statistics`,
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Информация о КСК v2
		load_ksk_info(id) {
			return instance({
				url: `/company/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Поиск УК
		searchCompany(params) {
			return instance({
				url: `/company/searchCompanyByHouseAndCategory`,
				method: "get",
				params
			})
				.then(res => res)
				.catch(error => error);
		},

		// * Редактирование КСК
		edit_ksk(data, id) {
			return instance({
				url: `/company/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * изменение КСК
		director_change(data, id) {
			return instance({
				url: `/company/${id}/directorChange`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Информация о КСК по БИН
		load_ksk_info_bin(bin) {
			return instance({
				url: `/v1/ksk/search`,
				method: "post",
				params: {
					bin,
				},
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},

		// * Создание КСК
		register_ksk(data) {
			return instance({
				url: `/company`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},

		// * Добавление файлов
		load_files(data) {
			return instance({
				url: `/files`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},
		// Получение файла
		load_file(id) {
			return instance({
				url: `/files/${id}`,
				method: "get",
				responseType: 'blob'
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},
		// Получение картинки
		download_file(id) {
			return instance({
				url: `/files/${id}/download-png`,
				method: "get",
				responseType: 'blob'
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},
		// * Удаление файлов
		delete_files(id) {
			return instance({
				url: `/files/${id}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},
		// Аналитика
		load_analitics_requests(params, sort) {
			let endpoint = '/analitics/request';
			switch (sort) {
				case 'by-houses':
					endpoint = '/analitics/request/houses';
					break;
				case 'by-rooms':
					endpoint = '/analitics/request/houses?analitics_type=rooms';
					break;
				case 'by-managers':
					endpoint = '/analitics/request/houses?analitics_type=managers';
					break;
				case 'by-executors':
					endpoint = '/analitics/request/employees';
					break;
				case 'by-categories':
					endpoint = '/analitics/request/categories';
					break;
				default:
					endpoint = '/analitics/request'; 
					break;
			}
			return instance({
				url: endpoint,
				method: 'get',
				params,
			})
				.then((res) => res.data)
				.catch((error) => {
					console.error('Error in API call:', error);
					throw error;
				});
		},		
		export_excel_analitics(params, sort) {
			let endpoint = '/analitics/request';
			switch (sort) {
				case 'by-houses':
					endpoint = '/analitics/request/houses';
					break;
				case 'by-rooms':
					endpoint = '/analitics/request/houses?analitics_type=rooms';
					break;
				case 'by-managers':
					endpoint = '/analitics/request/houses?analitics_type=managers';
					break;
				case 'by-executors':
					endpoint = '/analitics/request/employees';
					break;
				case 'by-categories':
					endpoint = '/analitics/request/categories';
					break;
				default:
					endpoint = '/analitics/request'; 
					break;
			}
			return instance({
				url: endpoint,
				responseType: "blob",
				contentType:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				params,
			})
			.then(res => window.URL.createObjectURL(new Blob([res.data])))
			.catch(error => error);
		},		

		...analytic(instance),
		...board(instance),
		...notifications(instance),
		...houses(instance),
		...entry(instance),
		...employee(instance),
		...users(instance),
		...gates(instance),
		...qr(instance),
		...qrNext(instance),
		...tenants(instance),
		...requests(instance),
		...vote(instance),
		...questions(instance),
		...pricelists(instance),
	};
}
